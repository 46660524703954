import { Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'

const compName='AboutUs';
const AboutUs = () => {

    const desc=`Welcome to Infinito Meta AI, a trailblazer in creating an advanced web3 ecosystem 
                                    where businesses can harness the power of cutting-edge Web3 platforms for seamless payment processing
                                    solutions for companies and individual needs.`;
 
  return (
    <>
            <section className="community py-5 d-flex align-items-center" id="comheader">

                    <div className="communityoverlay"></div>

                    <div className="container content text-light py-5"  data-aos="fade-right"> 
                        <h1 className="headline"><span className="home_text"> ABOUT US</span></h1>                       

                        <h5 className="headline">{desc}</h5>

                    </div> 
            </section> 

    

            {/* <!-- About --> */}
                <AboutUsSection/>
            {/* <!-- end of about --> */}



    </>
  );
};


function AboutUsSection() {
	return (
        <>
                <section className="about d-flex align-items-center text-light py-5" id="about">
                    <div className="container" >
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-7" data-aos="fade-right">
                                <p>ABOUT US</p>
                                <h1>We are thriving WEB3 <br/> product company</h1>
                                <p className="py-2 para-light">Welcome to Infinito Meta AI, a trailblazer in creating an advanced web3 ecosystem 
                                    where businesses can harness the power of cutting-edge Web3 platforms for seamless payment processing
                                    solutions for companies and individual needs.</p>
                                <p className="py-2 para-light"></p>

                                <p>OUR VISION</p>
                                <p className="py-2 para-light">
                                    Our vision is to enable every business and individual to harness the benefit of Web3
                                    on their daily life and business operations. We continuously thrive to revolutionize 
                                    the way businesses operate by providing a robust, decentralized infrastructure that 
                                    enhances financial operations, improves efficiency, and drives growth. We aim to be 
                                    the leading provider of innovative Web3 solutions that empower businesses to 
                                    achieve their full potential in the digital age
                                </p>

                                <p>OUR MISSION</p>
                                <p className="py-2 para-light">
                                    At Infinito Meta AI, our mission is to simplify and streamline financial processes for businesses 
                                    of all sizes. We are committed to delivering state-of-the-art Web3 technologies that offer 
                                    unparalleled security, transparency, and efficiency. By integrating blockchain and other 
                                    decentralized technologies, we ensure that your financial operations are faster, more secure, 
                                    and more reliable than ever before.
                                </p>

                                <div className="my-3">
                                    <a className="btn" href="#your-link">Learn More</a>
                                </div>
                            </div>
                            <div className="col-lg-5 text-center py-4 py-sm-0" data-aos="fade-down"> 
                                <img className="img-fluid" src="./assets/images/about.jpg" alt="about" />
                            </div>
                        </div> 
                    </div> 
                </section> 
        </>
	);
}




export default AboutUs;
