import axios from './axios.jsx';

import { REFRESH_TOKEN_URL} from '../api/URL.jsx';
//const REFRESH_TOKEN_URL = '/public/refreshtoken';


const compName='useRefreshToken';
const useRefreshToken = () => {

    const refresh = async () => {

        var refreshToken= localStorage.getItem('refreshToken');
        console.log(compName+': Requesting with Refresh Token : ',refreshToken);    
        
        if(refreshToken){
            refreshToken='Bearer '+refreshToken;
        }
        
        const response = await axios.get(REFRESH_TOKEN_URL, {
                                                                withCredentials: true,
                                                                headers: {'Authorization': refreshToken}
                                                            });
                                                            
        
        console.log(compName+': New access token received : '+response.data.access_token, response);    

        return response.data.access_token;
    }
    return refresh;
};

export default useRefreshToken;
