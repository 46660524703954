
export const PROD_ENV='PROD';
export const ACCESS_TOKEN_KEY='accessToken';
export const REFRESH_TOKEN_KEY='refreshToken';
export const BEARER='Bearer ';
export const AUTHORIZATION='Authorization';

export const SUCCESS_TOAST='success';
export const ERROR_TOAST='error';
export const INFO_TOAST='info';
export const WARNING_TOAST='warning';
