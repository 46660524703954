import React, { useState ,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { TelephoneFill,Facebook,Youtube, Instagram,Linkedin, EnvelopeFill} from 'react-bootstrap-icons'
import AppUtil from '../../utils/apputils';
import SocialHeader from './socialheader';

const compName='Footer';
const Footer = () => {

    const supportmobile='SITE_CONTACT_MOBILE';
    const supportemail='SITE_CONTACT_MAIL';
    const appSettingList=useSelector(state => state.appdataReducer.appSettingListSD);
    const { getAppData} = AppUtil();    

    return (
    <>
        {/* <!-- Footer --> */}
        {/* <div className="container-fluid bg-dark pt-5 px-sm-3 px-md-5 mt-5">
        <div className="row py-4">
            <div className="col-lg-3 col-md-6 mb-5">
                <h5 className="mb-4 text-uppercase font-weight-bold">Get In Touch</h5>               

                <p className="font-weight-medium"><i className="fa fa-phone-alt mr-2"><TelephoneFill/></i>{getAppData(supportmobile)}</p>
                <p className="font-weight-medium"><i className="fa fa-envelope mr-2"><EnvelopeFill/></i>{getAppData(supportemail)}</p>

            </div>

           
        </div>
        </div> */}
        <section className="footer text-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 py-4 py-md-5">
                        <div className="d-flex align-items-center">
                            <h4 className="">Infinito Meta AI</h4>
                        </div>
                        <p className="py-3 para-light">
                            Thank you for being part of the crypto revolution with us! <br/><br/>
                            We're constantly improving and innovating to enhance your experience in the crypto space. 
                            Your participation helps us to shape a secure and dynamic financial future.<br/><br/>
                            Stay tuned and continue being a part of this exciting journey!
                        </p>

                    </div> 
                    <div className="col-lg-4 py-4 py-md-5">                    
                    <ul className="navbar-nav ml-n2">
                        <li className="nav-item border-secondary">
                        <SocialHeader/> 

                        </li>
                    </ul>                
                    </div>
    
                    {/* <div className="col-lg-3 py-4 py-md-5">
                        <div>
                            <h4 className="py-2">Quick Links</h4>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="index.html#about"><p className="ms-3">About</p></a>
                                
                            </div>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="index.html#services"><p className="ms-3">Services</p></a>
                            </div>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="index.html#plans"><p className="ms-3">Plans</p></a>
                            </div>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="index.html#contact"><p className="ms-3">Contact</p></a>
                            </div>
                        </div>
                    </div>  */}
    
                    {/* <div className="col-lg-3 py-4 py-md-5">
                        <div>
                            <h4 className="py-2">Useful Links</h4>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="privacy.html"><p className="ms-3">Privacy</p></a>
                                
                            </div>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="terms.html"><p className="ms-3">Terms</p></a>
                            </div>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="#your-link"><p className="ms-3">Disclaimer</p></a>
                            </div>
                            <div className="d-flex align-items-center py-2">
                                <i className="fas fa-caret-right"></i>
                                <a href="#your-link"><p className="ms-3">FAQ</p></a>
                            </div>
                        </div>
                    </div>  */}
    
                    <div className="col-lg-4 py-4 py-md-5">
                        <div className="d-flex align-items-center">
                            <h4>Newsletter</h4>
                        </div>
                        <p className="py-3 para-light">Receive our communication about new development and services.</p>
                        <div className="d-flex align-items-center">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control p-2" placeholder="Enter Email" aria-label="Recipient's email" aria-describedby="button-addon2" />
                                <button className="btn-secondary text-light" id="button-addon2"><i className="fas fa-envelope fa-lg"></i></button>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div> 
        </section> 
        {/* <!-- end of footer --> */}

        {/* <!-- Bottom --> */}
        <div className="bottom py-2 text-light" >
            <div className="container d-flex justify-content-between">
                <div>
                    <p>Copyright © Infinito Meta AI</p>
                    <p><a href="https://infinitometaai.com/"> www.infinitometaai.com</a></p>
                </div>
                <div>
                    <i className="fab fa-ethereum fa-lg p-1"></i>
                    <i className="fab fa-bitcoin fa-lg p-1"></i>
                </div>
            </div> 
        </div> 
        {/* <!-- end of bottom --> */}
    
        
        {/* <!-- Back To Top Button --> */}
        <button  id="myBtn">
            <img src="assets/images/up-arrow.png" alt="alternative"/>
        </button>
        {/* <!-- end of back to top button --> */}
       

    </>
    )
}

export default Footer