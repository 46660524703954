
import Webservice from './webservice.jsx';

const compName='WebserviceGateway';
const WebserviceGateway = () => {

    const {securedPrivatePost, publicPost,securedPrivateFilePost,securedPrivateFileDownloadPost} = Webservice();

 
    const sendPrivatePost = async (sendUrl,bodyContent) => {
        var jsonBody= JSON.stringify(bodyContent); 
        console.log(`${compName}: Sending API request to pattern: ${sendUrl}`,jsonBody);
        const response = await securedPrivatePost(sendUrl, jsonBody);     
            if(response.responseCode == '702'){
                console.log(`${compName}: Expired token response received received:`);
            }           
        return response;
    }

    const sendPublicPost = async (sendUrl,bodyContent) => {
        var jsonBody= JSON.stringify(bodyContent); 
        console.log(`${compName}: Sending API Public request to pattern: ${sendUrl}`,jsonBody);
        const response = await publicPost(sendUrl, jsonBody);                
        return response;
    }

    const sendFileDownload = async (sendUrl,bodyContent) => {
        var jsonBody= JSON.stringify(bodyContent); 
        console.log(`${compName}: Sending File Download API request to pattern: ${sendUrl}`,jsonBody);
        const response = await securedPrivateFileDownloadPost(sendUrl, jsonBody);                
        return response;
    }

    const sendFile = async (sendUrl,bodyContent) => {
        console.log(`${compName}: Sending File API request to pattern: ${sendUrl}`);
        const response = await securedPrivateFilePost(sendUrl, bodyContent);                
        return response;
    }

    return {
        sendPrivatePost,sendPublicPost,sendFileDownload,sendFile
    }

}
export default WebserviceGateway;
