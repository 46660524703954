import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import store from './store.jsx';

import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/aos.min.css';
import './assets/css/swiper.css';
import './assets/css/style.css';


import MasterContainer from '@appcomponents/structure/base/MasterContainer.jsx';
import MUITheme from '@appcomponents/theme/MUITheme.jsx';
import { ENV } from './Config.jsx';

function App() {

  console.log('Website is starting env: ', ENV);

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await loadScript('./assets/js/jquery-3.7.1.min.js');
        await loadScript('./assets/js/bootstrap.min.js');
        await loadScript('./assets/js/purecounter.min.js');
        await loadScript('./assets/js/swiper.min.js');
        await loadScript('./assets/js/aos.js');
        await loadScript('./assets/js/script.js');
      } catch (error) {
        console.error('Error loading script:', error);
      }
    };

    loadScripts();

    return () => {
      // Clean up scripts
      const scripts = document.querySelectorAll('script');
      scripts.forEach((script) => {
        if (
          script.src.includes('jquery-3.7.1.min.js') ||
          script.src.includes('bootstrap.min.js') ||
          script.src.includes('purecounter.min.js') ||
          script.src.includes('swiper.min.js') ||
          script.src.includes('aos.js') ||
          script.src.includes('script.js')
        ) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  return (
    <Provider store={store}>
        
        <MUITheme>
            <MasterContainer/>        
        </MUITheme>
    </Provider>
  );
}

export default App;
