import React, { useState,useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import MembershipRewards from './membershiprewards';
import { Card, Grid, Stack } from '@mui/material';
import NewRewardsCalculator from './newrewardscalculator';

const compName='ComCalculator';
const RewardsCalculator = () => {


 
  return (
    <>


    {/* <!-- About --> */}
        <AboutProgramSection/>
    {/* <!-- end of about --> */}


    </>
  );
};



function AboutProgramSection() {

    const joiningFee = 50; // 50 UST
    const topupFee = 50; // 50 USDT
    const levels = Array.from({ length: 10 }, (_, i) => i + 1); // Levels 1 to 10
  
   
  const [selectedTopupLevel, setSelectedTopupLevel] = useState(1);
  const [directReferrals, setDirectReferrals] = useState(0);
  const [membersAtLevels, setMembersAtLevels] = useState(Array(10).fill(0));

  useEffect(() => {
    const maxMembersAtLevel = levels.map(level => Math.pow(3, level - 1));
    setMembersAtLevels(maxMembersAtLevel);
  }, []);

  const handleLevelChange = (event) => {
    setSelectedTopupLevel(parseInt(event.target.value, 10));
  };

  const handleDirectReferralsChange = (event) => {
    setDirectReferrals(parseInt(event.target.value, 10));
  };

  const handleMembersAtLevelsChange = (level, event) => {
    const newMembersAtLevels = [...membersAtLevels];
    const maxMembers = Math.pow(3, level - 1);
    const value = parseInt(event.target.value, 10);

    if (value <= maxMembers) {
      newMembersAtLevels[level - 1] = value;
    } else {
      newMembersAtLevels[level - 1] = maxMembers;
      alert(`Maximum members for Level ${level} is ${maxMembers}`);
    }
    setMembersAtLevels(newMembersAtLevels);
  };

  const topupFeesPaid = topupFee * selectedTopupLevel;
  const totalFeesPaid = joiningFee + topupFeesPaid;
  const directReferralRewards = 20 * directReferrals;

  const maxMembersAtLevel = (level) => Math.pow(3, level - 1);

  const joiningLevelRewards = membersAtLevels.map((members, level) => 
    maxMembersAtLevel(level + 1) * 1
  ).reduce((a, b) => a + b, 0);
  
	return (
        <>

            <section className="rewards py-5 d-flex align-items-center" >
              

              <div className="rewardsoverlay"></div>
            
              <div className="container content text-light py-5"  data-aos="fade-right"> 
                  <h1 className="headline">Earn <br/> <span className="home_text"> Calculate your Rewards</span><br/>now</h1>
                  <p className="para para-light py-3">
                      Play with the below parameters and see how easily you can earn          
                  </p>
                  <div className="d-flex align-items-center">
                      <p className="p-2"><i className="fas fa-atom fa-lg"></i></p>
                      <p>Rewards for Referral.</p>  
                  </div>
                  <div className="d-flex align-items-center">
                      <p className="p-2"><i className="fas fa-wifi fa-lg"></i></p>
                      <p>Spontanous rewards through tokens.</p>  
                  </div>
                  <div >
                      <a className="btn" href="#about">Read More</a>
                  </div>
              </div> 
            </section> 
            <br/>
            <MembershipRewards/>
            {/* <NewRewardsCalculator/> */}



                
        </>
	);
}




export default RewardsCalculator;
