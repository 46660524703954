import {createSlice} from '@reduxjs/toolkit'

import {
  updateDashboardMenu,updateHomeMenu
} from '../reducers/menureducer';

const menuSelection = {menuValue:'',activeMenu:''};
const initialState = {  
  dashboardMenuSD: menuSelection ,
  homeMenuSD: menuSelection ,
};

const reducers =  {
  updateDashboardMenu,
  updateHomeMenu,
}

export const menuSlice = createSlice({
    name:'menu',
    initialState: initialState,
    reducers:reducers
})

export const menuActions=menuSlice.actions;
export default menuSlice.reducer;