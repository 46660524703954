import { SUCCESS_CODE } from '@appcomponents/utils/errorcodes.jsx';
import axios from './axios.jsx';
import useAxiosPrivate from './useaxiosprivate.jsx';

const compName='Webservice';
const Webservice = () => {
    
    const axiosPrivate = useAxiosPrivate();

    //Function to send only authorized webservice POST method call
    const securedPost = async (mappingPattern, jsonBody) => {
        
        var responseData =validatePostRequest(mappingPattern, jsonBody);
        if(responseData.responseCode !== "1"){
            return responseData;
        }

        var authToken= localStorage.getItem('accessToken');
        if(authToken){
            authToken='Bearer '+authToken;
        }else{
            return responseData;
        }

            console.log(`${compName}: Sending SecuredPost request with pattern: ${mappingPattern} body: `+jsonBody);
            try {
                    const response = await axios.post(mappingPattern,jsonBody,
                                                {
                                                    headers: { 
                                                        'Content-Type': 'application/json',
                                                        'Authorization': authToken
                                                    },                                                  
                                                    withCredentials: true,
                                                    
                                                }
                                                );

                    // Check if the response was successful
                    if (response) {
                        responseData = response.data;  
                        console.log(`${compName}:SecuredPost Response received `);
                        console.log(JSON.stringify(responseData))
                    } else{
                        console.error(`${compName}:: SecuredPost Response received is invalid`);
                        console.error(JSON.stringify(response))
                    }
                    
            
            } catch (err) {
                console.error(err);
                if (!err?.response) {
                console.error(`${compName}:No Server Response`);
                responseData.responseCode='50';
                responseData.responseMessage='No Server Response'
                } else if (err.response?.status === 403) {
                console.error(`${compName}: Forbiden Access`);
                responseData.responseCode=err.response?.status;
                responseData.responseMessage='Forbidden Access'
                } else {
                responseData.responseCode=err.response?.status;
                responseData.responseMessage='Error'
                console.error(`${compName}: Response Error`);
                }
                
            }
        
    
        console.log(`${compName}:  Response Code : ${responseData.responseCode}
                        Response Message: ${responseData.responseMessage}`);     

        return responseData;
    }

    const securedGet = async (mappingPattern) => {
        var responseData =validateGetRequest(mappingPattern);
        if(responseData.responseCode !== "1"){
            return responseData;
        }

        var authToken= localStorage.getItem('accessToken');
        if(authToken){
            authToken='Bearer '+authToken;
        }else{
            return responseData;
        }

            console.log(`${compName}: SecuredGet request sent to pattern: ${mappingPattern}`);
            try {
                const response = await axios.get(mappingPattern,
                                                 {withCredentials: true,
                                                    headers: {                                                      
                                                        'Authorization':authToken
                                                    }
                                                }
                                                );

                // Check if the response was successful
                if (response) {
                    responseData = response.data;  
                    console.log(`${compName}:SecuredGet Response received `);
                    console.log(JSON.stringify(responseData))
                } else{
                    //console.log('SecuredPost Response received is invalid');
                    console.error(`${compName}:SecuredGet Response received is invalid`);
                    console.error(JSON.stringify(response))
                }
            
            
            } catch (err) {
                console.error(err);
                if (!err?.response) {
                console.error(`${compName}:No Server Response`);
                responseData.responseCode='50';
                responseData.responseMessage='No Server Response'
                } else if (err.response?.status === 403) {
                console.error(`${compName}: Forbiden Access`);
                responseData.responseCode=err.response?.status;
                responseData.responseMessage='Forbidden Access'
                } else {
                responseData.responseCode=err.response?.status;
                responseData.responseMessage='Error'
                console.error(`${compName}: Response Error`);
                }
                
                
            }
        
    
            console.log(`${compName}:  Response Code : ${responseData.responseCode}
                            Response Message: ${responseData.responseMessage}`)    ; 

        return responseData;
    }


    function validateGetRequest (mappingPattern) {

        var responseData ={
            responseCode:"1",
            responseMessage:"App Error! Default Message"
        };
        
        if(mappingPattern ==null || mappingPattern == ''){
            responseData.responseCode="48";
            responseData.responseMessage="Webservice App Error! Mapping url is empty!";
            return responseData;
        }

        return responseData;
    }


    function validatePostRequest (mappingPattern, jsonBody) {

        var responseData ={
            responseCode:"1",
            responseMessage:"App Error! Default Message"
        };
        
        if(mappingPattern ==null || mappingPattern == ''){
            responseData.responseCode="48";
            responseData.responseMessage="Webservice App Error! Mapping url is empty!";
            return responseData;
        }

        if(jsonBody ==null || jsonBody == ''){
            responseData.responseCode="49";
            responseData.responseMessage="App Error! Request body is empty!";
            return responseData;
        }

        return responseData;
    }

    //Function to send public webservice POST method call with no authorization
    const publicPost = async (mappingPattern, jsonBody) => {
        
        var responseData =validatePostRequest(mappingPattern, jsonBody);
        if(responseData.responseCode !== "1"){
            return responseData;
        }

        console.log(`${compName}: PublicPost request sent to pattern: ${mappingPattern}`,jsonBody);
        try {
            const response = await axios.post(mappingPattern,jsonBody,
                                                {
                                                    headers: { 
                                                        'Content-Type': 'application/json'                                                    
                                                    }                                                
                                                }
                                                );
        
            
            // Check if the response was successful
            if (response) {
                responseData = response.data;  
                console.log(`${compName}:PublicPost Response received for pattern: ${mappingPattern}`);
                console.log(JSON.stringify(responseData))
            } else{
                console.error(`${compName}:PublicPost Response received is invalid for pattern: ${mappingPattern}`);
                console.error(JSON.stringify(response))
            }
            
        
        } catch (err) {
            console.error(err);
            if (!err?.response) {
            console.error(`${compName}:No Server Response`);
            responseData.responseCode='50';
            responseData.responseMessage='No Server Response'
            } else if (err.response?.status === 403) {
            console.error(`${compName}: Forbiden Access`);
            responseData.responseCode=err.response?.status;
            responseData.responseMessage='Forbidden Access'
            } else {
            responseData.responseCode=err.response?.status;
            responseData.responseMessage='Error'
            console.error(`${compName}: Response Error`);
            }
            
        }
        
    
        console.log(`${compName}: Response Code : ${responseData.responseCode}
                                Response Message: ${responseData.responseMessage}`);    

        return responseData;
    }

    //Function to send only authorized webservice call
    const securedPrivatePost = async (mappingPattern, jsonBody) => {
       
        var responseData =validatePostRequest(mappingPattern, jsonBody);
        if(responseData.responseCode !== "1"){
            return responseData;
        }

        var authToken= localStorage.getItem('accessToken');
        if(authToken){
            authToken='Bearer '+authToken;
        }else{
            return responseData;
        }

        
        console.log(`${compName}: securedPrivatePost request sent to pattern: ${mappingPattern}`,jsonBody);    
        
        try {
            const response = await axiosPrivate.post(mappingPattern,jsonBody,
                                                        {
                                                            headers: { 
                                                                'Content-Type': 'application/json',
                                                                'Authorization': authToken
                                                            }                                                    
                                                        }
                                                    );


            // Check if the response was successful
            if (response) {
                responseData = response.data;  
                console.log(`${compName}:SecuredPrivatePost Response received for pattern: ${mappingPattern}`);
                console.log(JSON.stringify(responseData))
            } else{
                console.error(`${compName}:SecuredPrivatePost Response received is invalid for pattern: ${mappingPattern}`);
                console.error(JSON.stringify(response))
            }
        
        
        } catch (err) {
    
            if (!err?.response) {
            console.error(`${compName}:No Server Response`);
            responseData.responseCode='50';
            responseData.responseMessage='No Server Response'
            } else if (err.response?.status === 403) {
            console.error(`${compName}: Forbiden Access`);
            responseData.responseCode=err.response?.status;
            responseData.responseMessage='Forbidden Access'
            } else {
            responseData.responseCode=err.response?.status;
            responseData.responseMessage=(err.response?.data)? err.response?.data:'Error'
            console.error(`${compName}: Response Error`);
            }
            console.error(err);
            
        }
        
        console.log(`${compName}:  Response Code : ${responseData.responseCode}
        Response Message: ${responseData.responseMessage} for pattern: ${mappingPattern}`);        

        return responseData;
    }

    const securedPrivateFilePost = async (mappingPattern, data) => {
       
        var responseData =validatePostRequest(mappingPattern, data);
        if(responseData.responseCode !== "1"){
            return responseData;
        }

        var authToken= localStorage.getItem('accessToken');
        if(authToken){
            authToken='Bearer '+authToken;
        }else{
            return responseData;
        }

        
        console.log(`${compName}: securedPrivateFilePost request sent to pattern: ${mappingPattern}`,data);    
        
        try {
            const response = await axiosPrivate.post(mappingPattern,data,
                                                        {
                                                            headers: { 
                                                                'Content-Type': 'multipart/form-data',
                                                                'Authorization': authToken
                                                            }                                                    
                                                        }
                                                    );


            // Check if the response was successful
            if (response) {
                responseData = response.data;  
                console.log(`${compName}:securedPrivateFilePost Response received for pattern: ${mappingPattern}`);
                console.log(JSON.stringify(responseData))
            } else{
                console.error(`${compName}:securedPrivateFilePost Response received is invalid for pattern: ${mappingPattern}`);
                console.error(JSON.stringify(response))
            }
        
        
        } catch (err) {
            console.error(err);
            if (!err?.response) {
            console.error(`${compName}: securedPrivateFilePost No Server Response`);
            responseData.responseCode='50';
            responseData.responseMessage='No Server Response'
            } else if (err.response?.status === 403) {
            console.error(`${compName}:securedPrivateFilePost Forbiden Access`);
            responseData.responseCode=err.response?.status;
            responseData.responseMessage='Forbidden Access'
            } else {
            responseData.responseCode=err.response?.status;
            responseData.responseMessage='Error'
            console.error(`${compName}: securedPrivateFilePost Response Error`);
            }
            
            
        }
        
        console.log(`${compName}: securedPrivateFilePost Response Code : ${responseData.responseCode}
        Response Message: ${responseData.responseMessage}`);        

        return responseData;
    }

    //Function to send only authorized webservice call to download file
    const securedPrivateFileDownloadPost = async (mappingPattern, jsonBody) => {
    
        var responseData =validatePostRequest(mappingPattern, jsonBody);
        if(responseData.responseCode !== "1"){
            return responseData;
        }

        var authToken= localStorage.getItem('accessToken');
        if(authToken){
            authToken='Bearer '+authToken;
        }else{
            return responseData;
        }

        
        console.log(`${compName}: securedPrivateFileDownloadPost request sent to pattern: ${mappingPattern}`,jsonBody);    
        
        try {
            const response = await axiosPrivate.post(mappingPattern,jsonBody,
                                                        {
                                                            headers: { 
                                                                'Content-Type': 'application/json',
                                                                'Authorization': authToken
                                                            }        ,
                                                            responseType: 'blob'                                            
                                                        },
                                                        
                                                    );

            // Check if the response was successful
            if (response) {

                const blob = new Blob([response.data], { type: response.headers['content-type'] });


                
                // Create a Blob URL for the file with correct file name
                const resourceName='IDPBTabu New 2.pdf';
                const fileName = response.headers['content-disposition'] ? 
                response.headers['content-disposition'].split(';')[1].split('=')[1].trim() : resourceName;
                const fileUrl = URL.createObjectURL(blob, { type: response.headers['content-type'] });

            
                // Open the file in a new tab
                window.open(fileUrl, '_blank');
                

                responseData.responseCode=SUCCESS_CODE;
                responseData.responseMessage='File download successful';
                //responseData = response.data;  
                console.log(`${compName}:securedPrivateFileDownloadPost Response received for pattern: ${mappingPattern}`);
                console.log(JSON.stringify(responseData))
            } else{
                console.error(`${compName}:securedPrivateFileDownloadPost Response received is invalid for pattern: ${mappingPattern}`);
                console.error(JSON.stringify(response))
            }
        
        
        } catch (err) {
            console.error(err);
            if (!err?.response) {
                console.error(`${compName}:No Server Response`);
                responseData.responseCode='50';
                responseData.responseMessage='No Server Response'
            } else if (err.response?.status === 403) {
                console.error(`${compName}: Forbiden Access`);
                responseData.responseCode=err.response?.status;
                responseData.responseMessage='Forbidden Access'
            }else if (err.response?.status === 400) {
                console.error(`${compName}: Invalid Request`,err);
                responseData.responseCode=err.response?.status;
                responseData.responseMessage='Invalid request'
                } 
            else {
                responseData.responseCode=err.response?.status;
                responseData.responseMessage='Error'
                console.error(`${compName}: Response Error`,err);
            }
            
            
        }
        
        console.log(`${compName}:  Response Code : ${responseData.responseCode}
        Response Message: ${responseData.responseMessage}`);        

        return responseData;
    }

    return {securedPost,publicPost,securedGet,securedPrivatePost,
            securedPrivateFilePost,securedPrivateFileDownloadPost};
  };


  export default Webservice;
