import React from 'react'
import {useSelector} from 'react-redux'


import HomePage from '@appcomponents/pages/bzpages/homepage.jsx'
import MissingPage from '@appcomponents/pages/errorpages/missingpage.jsx'
import CommunityPage from '@appcomponents/pages/bzpages/communitypage.jsx'
import RewardsCalculator from '@appcomponents/pages/bzpages/rewardscalculator.jsx'
import FeesAndRewardsCalculator from '@appcomponents/pages/bzpages/FeesAndRewardsCalculator.jsx'
import AboutUs from '../../pages/bzpages/aboutus'
import Products from '../../pages/bzpages/products'


const Body = () => {

    //const userInfo = useSelector(state => state.userReducer.userSD)
    const menuValueObj = useSelector(state => state.menuReducer.homeMenuSD)
    const menuValue = (menuValueObj?.menuValue)?menuValueObj.menuValue:'HM';
    
    const ParentComponentSelction = (clickedValue) => {
        
        switch (clickedValue) {
            case 'HM':
                return <HomePage/>;
            case 'ABT':
                return <AboutUs/>;
            case 'PDS':
                return <Products/>;
            case 'CPH':
                return <CommunityPage/>;
            case 'CCL':
                return <RewardsCalculator/>;


            default:
                return <MissingPage/>; // or a default component or message
        }
    }

    return (
        <>                                   
            <div>{ParentComponentSelction(menuValue)}</div>                
        </>
    )
}

export default Body