
function updateDashboardMenu (state, action)  {    
    state.dashboardMenuSD = action.payload;    
}

function updateHomeMenu (state, action)  {    
  state.homeMenuSD = action.payload;    
}

export {
  updateDashboardMenu,updateHomeMenu
}