import { Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'

const compName='Products';
const Products = () => {

    const desc=`Infinito Meta AI values the community. All beautiful products are built for the community needs.
                            We thrive to build innovative products and give it back to the community. You can reap benefits in building the community.`;
 
  return (
    <>
            <section className="community py-5 d-flex align-items-center" id="comheader">

                    <div className="communityoverlay"></div>

                    <div className="container content text-light py-5"  data-aos="fade-right"> 
                        <h1 className="headline"><span className="home_text"> PRODUCTS</span></h1>                       

                        <h5 className="headline">{desc}</h5>

                    </div> 
            </section> 

    

            <ProductsSection/>



    </>
  );
};

function ProductsSection() {
	return (
        <>
            <section className="services d-flex align-items-center py-5" id="products">
                <div className="container text-light">
                    <div className="text-center pb-4" >
                        <p>OUR PRODUCTS</p> 
                        <h2 className="py-2">Explore unlimited possibilities</h2>
                        <p className="para-light">
                            Web3 and Decentralization have provided a platform where transparency and privacy can go hand in hand.<br/> Our Web3 products are 
                            built to bridge the gap between your needs and the solutions available through the blockchain and decentralization.    
                        </p>
                    </div>
                    <div className="row gy-4 py-2" data-aos="zoom-in">
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-donate fa-2x"></i>

                                <h4 className="py-2">PAYMENT COLLECTIONS</h4>
                                <p className="para-light">
                                    Enhance your payment collection process with our secure and efficient system. 
                                    Accept payments in from any where in the world, offering flexibility and convenience to your clients
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-file-alt fa-2x"></i>
                                <h4 className="py-2"> INVOICE PROCESSING</h4>
                                <p className="para-light">
                                    Automate your invoicing with our sophisticated Web3 platform, 
                                    reducing errors and speeding up processing times. 
                                    Enjoy the benefits of smart contracts to ensure accuracy and timely payments!
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-stream fa-2x"></i>                    
                                <h4 className="py-2">SUBSCRIPTION SERVICES</h4>
                                <p className="para-light">
                                    Leverage the subscription services to effectively establish your consistent and reliable payment collection process for the various services and products that you offer.
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-ribbon fa-2x"></i>                     
                                <h4 className="py-2">CHARITY COLLECTIONS</h4>
                                <p className="para-light">
                                    Are you struggling to receive donations for your charity globally? You can now set up your donation gateway and simplify the donation process for yourself and the donars.    
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-shipping-fast fa-2x"></i>
                                <h4 className="py-2">BULK PAYMENTS</h4>
                                <p className="para-light">
                                    Tired of sending payments individually. Use our bulk payment service and simplify your bulk payment process such as salary transfers, bulk vendor or invoice payments!
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-tv fa-2x"></i>
                                <h4 className="py-2">Crypto Services</h4>
                                <p className="para-light">
                                    Although blockchain offers decentralization and speedy transactions, it lacks user-friendly features. Our WEB3 interfaces help newcomers easily navigate the blockchain landscape!
                                </p>
                            </div>                    
                        </div>
                    </div> 
                </div>
            </section> 

        </>
	);
}





export default Products;
