
export const ENV = window.env?.VITE_APP_ENV || 'DEV';
export const HOST_URL = window.env?.VITE_API_HOST || 'http://localhost:8080';



console.log('Website is starting in env: ', ENV);
console.log("AppConfig: API HOST_URL: "+HOST_URL);



export const setApplicationEnvironment = (appSettingsList) => {

  if(!appSettingsList){
    return;
  }

  var LOG_LEVEL='';
  const clientLogLevel = appSettingsList.find(item => item.key === "CLIENT_LOG_LEVEL");
  if (clientLogLevel) {
    console.log("CLIENT_LOG_LEVEL value:", clientLogLevel.value);
    LOG_LEVEL=clientLogLevel.value
  } else {
    console.warn("CLIENT_LOG_LEVEL not found in app_settings_list");
    return;
  }

 
  console.log("Application is starting with LOG_LEVEL: "+LOG_LEVEL);
  
  if (LOG_LEVEL === 'DEBUG') {   
   
    //Enabled all log level

  }

  if (LOG_LEVEL === 'INFO') {   
   
    console.log = () => {};
        
  }

  if (LOG_LEVEL === 'WARN') {
    
    console.info = () => {};
    console.log = () => {};

  }

  if (LOG_LEVEL === 'ERROR') {
    
    console.info = () => {};
    console.log = () => {};
    console.warn = () => {};    
  }

  //Disable all level
  if (LOG_LEVEL === 'NONE') {
      
    console.info = () => {};
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};

  }

};
