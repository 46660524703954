import {createSlice} from '@reduxjs/toolkit'

import {
  updateWalletDefList,updateSMList,updateGSList,updateAppList
} from '../reducers/appdatareducer';

const initialdata = {};
const initialState = {  
  walletDefListSD: initialdata ,
  homeMenuSD: initialdata ,
  socialMediaListSD: initialdata ,
  generalSettingListSD: initialdata ,
  appSettingListSD: initialdata ,
};

const reducers =  {  
  updateWalletDefList,  
  updateSMList,
  updateGSList,
  updateAppList
}

export const appDataSlice = createSlice({
    name:'appdata',
    initialState: initialState,
    reducers:reducers
})

export const appDataActions=appDataSlice.actions;
export default appDataSlice.reducer;