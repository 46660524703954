//***************************************************************
//                  Frontend Navigation URIs
//***************************************************************
export const URI_DASHBOARD_HOME = '/dashboard';
export const URI_LOGIN = '/login';
export const URI_SIGNUP = '/signup/:sponsorid?';
export const URI_LOGOUT = '/logout';
export const URI_UNAUTHORIZED = '/unauthorized';

export const URI_PROFILE = '/profile';
export const URI_WALLETS = '/wallets';
export const URI_TRANSACTIONS = '/transactions';
export const URI_WITHDRAW = '/withdraw';
export const URI_DEPOSIT = '/deposit';
export const URI_CONTACT = '/contact';


//***************************************************************
//                  Server API URIs
//***************************************************************

//Server matching patterns  - url
export const SIGNUP_URL = '/public/register';
export const SIGNIN_URL = '/public/authenticate';
export const REFRESH_TOKEN_URL = '/public/refreshtoken';

//General URL Unsecured
export const GET_APPPUBLIC_DATA = '/apppublic/getappdata';

