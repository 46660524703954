import {configureStore} from '@reduxjs/toolkit'
import menuReducer from './appcomponents/features/slices/menuslice.jsx'
import appdataReducer from './appcomponents/features/slices/appdataslice.jsx'
import themeReducer from './appcomponents/features/slices/themeslice.jsx'


const store= configureStore({
    reducer:{
        menuReducer: menuReducer,
        appdataReducer: appdataReducer,
        theme: themeReducer,        
    }
  })

export default store;