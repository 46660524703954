import * as URL from "./URL.jsx";
import WebserviceGateway from "./webservicegateway.jsx";

const compName='GeneralAPI';
const GeneralAPI = () => {

    
    const {sendPublicPost} = WebserviceGateway();



    const getPublicData = async (bodyContent) => {       
        return await sendPublicPost(URL.GET_APPPUBLIC_DATA, bodyContent);      
    }

 
 

    return {
        getPublicData
    }

}
export default GeneralAPI;
