import { axiosPrivate } from "./axios.jsx";
import { useEffect } from "react";
import useRefreshToken from "./userefreshtoken.jsx";
import * as Constants from '@appcomponents/utils/constants.jsx'

const compName='useAxiosPrivate';
const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                
                var acessToken= localStorage.getItem(Constants.ACCESS_TOKEN_KEY);
                if(acessToken){
                    acessToken=Constants.BEARER+acessToken;
                }else{
                    return responseData;
                }

                if (!config.headers[Constants.AUTHORIZATION]) {
                    config.headers[Constants.AUTHORIZATION] = acessToken;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 702 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    if(newAccessToken && newAccessToken !==null){

                        localStorage.setItem(Constants.ACCESS_TOKEN_KEY, newAccessToken);
                        console.log(compName+': New access token stored in local : ',newAccessToken); 
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosPrivate(prevRequest);
                    }else{
                        console.error(compName+': Invalid New access token received : ',newAccessToken); 
                    }

                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [ refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;