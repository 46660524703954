import React, { useState,useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'

const compName='CommunityPage';
const CommunityPage = () => {
 
    const appSettingList=useSelector(state => state.appdataReducer.appSettingListSD);
    


  return (
    <>
        <section className="community py-5 d-flex align-items-center" id="comheader">

            <div className="communityoverlay"></div>

            <div className="container content text-light py-5"  data-aos="fade-right"> 
                <h1 className="headline">Join <br/> <span className="home_text"> THE FASTEST GROWING COMMUNITY</span><br/>now</h1>
                <p className="para para-light py-3">Infinito Meta AI values the community. All beautiful products are built for the community needs.
                    We thrive to build innovative products and give it back to the community. You can reap benefits in building the community.
                    </p>
                <div className="d-flex align-items-center">
                    <p className="p-2"><i className="fas fa-atom fa-lg"></i></p>
                    <p>Rewards for Referral.</p>  
                </div>
                <div className="d-flex align-items-center">
                    <p className="p-2"><i className="fas fa-wifi fa-lg"></i></p>
                    <p>Spontanous rewards through tokens.</p>  
                </div>
                <div >
                    <a className="btn" href="#about">Read More</a>
                </div>
            </div> 
        </section> 
    {/* <!-- end of home --> */}



    

    {/* <!-- About --> */}
        <AboutProgramSection/>
    {/* <!-- end of about --> */}

        {/* <!-- Information --> */}
        <InformationSection/>
    {/* <!-- end of information --> */}


    {/* <!-- Rewards --> */}
        <JoiningRewardsSection/>
        <TopupRewardsSection/>
        <MetaRewardsSection/>
    {/* <!-- end of Rewards --> */}


    {/* <!-- Plans --> */}
        <EarningsProjectionSection/>
    {/* <!-- end of plans --> */}



    </>
  );
};

function InformationSection() {
	return (

        <>

            <section className="rewardtitle text-light py-5" id="information">

                <div className="text-center pb-4" >
                        <p>We give back to the community</p> 
                        
                        <h1>
                            <span >
                             Community Rewards
                            </span>
                        </h1>
                </div>

                <div className="container" data-aos="zoom-in">
                    <div className="row">
                        
                        <div className="col-lg-4 d-flex align-items-center" >
                            <div className="p-2"><i className="fas fa-gifts fa-3x"></i></div>
                            <div className="ms-2">
                                
                                <h2>3 Categories</h2>
                            </div>
                        </div> 
                        <div className="col-lg-4 d-flex align-items-center" >
                            <div className="p-2"><i className="fas fa-th-list fa-3x"></i></div>
                            <div className="ms-2">
                                
                                <h2>8 Reward Types</h2>
                                
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex align-items-center" >
                            <div className="p-2">
                            <i className="far fa-money-bill-alt fa-3x"></i>
                            </div>
                            <div className="ms-2">
                                <h6>Earning Potential</h6>
                                <h2>3 Million USDT to Unlimited</h2>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>

            <section className="information" >
                <div className="container-fluid">  
                    <div className="row text-light">
                        <div className="col-lg-4 text-center p-5" data-aos="zoom-in">
                            <i className="fas fa-tachometer-alt fa-3x p-2"></i>
                            <h4 className="py-3">Joining Rewards</h4>
                            <p className="para-light">Earn rewards when you help a new Metizen join the community!</p>                            
                            <a className="btn" href="#joiningreward">Read More</a>
                            
                        </div>
                        <div className="col-lg-4 text-center p-5"  data-aos="zoom-in">
                            <i className="fas fa-clock fa-3x p-2"></i>
                            <h4 className="py-3">TopUp Rewards</h4>
                            <p className="para-light">Earn rewards as and when the Metizen tops up his level!</p>
                            <a className="btn" href="#topupreward">Read More</a>
                        </div>
                        <div className="col-lg-4 text-center p-5 text-dark"  data-aos="zoom-in"> 
                            <i className="fas fa-headset fa-3x p-2"></i>
                            <h4 className="py-3">Meta Rewards </h4>
                            <p className="para-light">Earn rewards, when your team earns their reward!</p>
                            <a className="btnlight" href="#metareward">Read More</a>
                        </div>
                    </div>
                </div> 
            </section> 
        </>
	);
}


function AboutProgramSection() {
	return (
        <>
                <section className="about d-flex align-items-center text-light py-5" id="about">
                    <div className="container" >
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-7" data-aos="fade-right">
                                <p>ABOUT COMMUNITY PROGRAM</p>
                                <h1>
                                    <span className="reward_text">
                                    The Fastest Growing & <br/>Highly Rewarding Community<br/>
                                    </span>
                                </h1>
                                <p>Built by Community Metizens</p>
                                <p className="py-2 para-light">
                                    The Infinito Meta AI Community program is designed thoughfully, that it is organically built.
                                    The community will be built by its own community metizens. The reward program is designed carefully that
                                    every Metizen is benifited when the community starts growing. Everybody earns the reward based on his team growth.

                                </p>
                                <p className="py-2 para-light"></p>

                                <p>How does it work?</p>
                                <p className="py-2 para-light">
                                    Every community will be connected to 3 new Metizens. The new Metizen can be referred by oneself or be introduced by 
                                    anyother Metizen in the community. The newly joined Metizen can further then connect 3 more Metizen directly under him/her.
                                    Individuals from anywhere in the world having access to the BlockChain will be able to join the community.
                                </p>

                                <p>How do the rewards work?</p>
                                <p className="py-2 para-light">
                                    There are 3 categories and 8 type of rewards that every Metizen can easily earn.
                                    You can read below on further details about the reward categories and reward types. 
                                    The rewards are distributed decentralized and spontanously. There are no approvals or intermediatory 
                                    to receive your rewards. You earn not only by referral, your earning starts just by joining the community.
                                    You can easily swap the rewards to USDT with decentralized conversion pannels.
                                </p>

                                <div className="my-3">
                                    <a className="btn" href="#information">Learn More</a>
                                </div>
                            </div>
                            <div className="col-lg-5 text-center py-4 py-sm-0" data-aos="fade-down"> 
                                <img className="img-fluid" src="./assets/images/network.jpg" alt="about" />
                            </div>
                        </div> 
                    </div> 
                </section> 
        </>
	);
}


const JOINING_REWARDS = [
    {
        id: 1,title: 'Sponsor Reward',icon: 'fas fa-donate fa-2x',
        content: `A Metizen sponsor reward is granted to a Metizen who introduces 
                    a new member into the Metaverse. The new joinee can be placed 
                    anywhere within the network tree. There are no limitation on the number of referrals.`,
        rewardvalue: '20 USDT / Joinee',
        potentialearning:'Unlimited'
    },
    {
        id: 2,title: 'Level Reward',icon: 'fas fa-donate fa-2x',
        content: `A Metizen sponsor reward is granted to a Metizen who introduces 
                    a new member into the Metaverse. The new joinee can be placed 
                    anywhere within the network tree. There are no limitation on the number of referrals.`,
        rewardvalue: '1 USDT / Joinee',
        potentialearning:'88,572'
    },
    
];

const TOPUP_REWARDS = [
    {
        id: 1,title: 'TopUp Reward',icon: 'fas fa-donate fa-2x',
        content: `When a Metizen tops up in the Metaverse, their direct sponsor will receive the Top-up Sponsor reward.`,
        rewardvalue: '10 USDT / TopUp',
        potentialearning:'Unlimited'
    },
    {
        id: 2,title: 'Level Reward',icon: 'fas fa-donate fa-2x',
        content: `When a Metizen completes a top-up, all Metizens up to 10 levels above will receive the Top-up Level rewards.`,
        rewardvalue: '1 USDT / TopUp',
        potentialearning:'885,720'
    },
    {
        id: 3,title: 'Bonus Reward',icon: 'fas fa-donate fa-2x',
        content: `When a Metizen completes a top-up, one Metizen within the 10 levels above will receive the Top-up Bonus Reward.`,
        rewardvalue: '20  USDT / TopUp',
        potentialearning:'1,771,440'
    },
    
];

const META_REWARDS = [
    {
        id: 1,title: 'Meta Reward',icon: 'fas fa-donate fa-2x',
        content: `Upon completing 3 direct referrals and achieving Meta-Level-2 (having 9 Metizens in total), 
                    the Metizen will receive a free reward equivalent to 50 USDT worth of IM-USD. 
                    This reward can be withdrawn as a cashback reward.`,
        rewardvalue: '50 USDT',
        
    },
    {
        id: 2,title: 'Sponsor Reward',icon: 'fas fa-donate fa-2x',
        content: `The sponsoring Metizen will receive 5 IMUSD whenever their referred Metizen claims the CASH BACK reward.`,        
        rewardvalue: '5 USDT / Reward Claim',
        potentialearning:'Unlimited'
    },
    {
        id: 3,title: 'Level Reward',icon: 'fas fa-donate fa-2x',
        content: `All Metizens up to 10 levels above will receive 2.5 IM-USD from every CASH BACK claim made by their team members up to 10 levels below them.`,
        rewardvalue: '2.5 USDT / Reward Claim',
        potentialearning:'221,430'
    },
    
];

function RewardDetailsSection() {
	return (
        <>
            <section className="services d-flex align-items-center py-5" id="products">
                <div className="container text-light">
                    <div className="text-center pb-4" >
                        <p>Reward Details</p> 
                        <h2 className="py-2">Explore unlimited possibilities</h2>
                        <p className="para-light">
                            Web3 and Decentralization have provided a platform where transparency and privacy can go hand in hand.<br/> Our Web3 products are 
                            built to bridge the gap between your needs and the solutions available through the blockchain and decentralization.    
                        </p>
                    </div>
                    <div className="row gy-4 py-2" data-aos="zoom-in">

                           <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-donate fa-2x"></i>

                                <h4 className="py-2">PAYMENT COLLECTIONS</h4>
                                <p className="para-light">
                                    Enhance your payment collection process with our secure and efficient system. 
                                    Accept payments in from any where in the world, offering flexibility and convenience to your clients
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-file-alt fa-2x"></i>
                                <h4 className="py-2"> INVOICE PROCESSING</h4>
                                <p className="para-light">
                                    Automate your invoicing with our sophisticated Web3 platform, 
                                    reducing errors and speeding up processing times. 
                                    Enjoy the benefits of smart contracts to ensure accuracy and timely payments!
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-stream fa-2x"></i>                    
                                <h4 className="py-2">SUBSCRIPTION SERVICES</h4>
                                <p className="para-light">
                                    Leverage the subscription services to effectively establish your consistent and reliable payment collection process for the various services and products that you offer.
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-ribbon fa-2x"></i>                     
                                <h4 className="py-2">CHARITY COLLECTIONS</h4>
                                <p className="para-light">
                                    Are you struggling to receive donations for your charity globally? You can now set up your donation gateway and simplify the donation process for yourself and the donars.    
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-shipping-fast fa-2x"></i>
                                <h4 className="py-2">BULK PAYMENTS</h4>
                                <p className="para-light">
                                    Tired of sending payments individually. Use our bulk payment service and simplify your bulk payment process such as salary transfers, bulk vendor or invoice payments!
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-tv fa-2x"></i>
                                <h4 className="py-2">Crypto Services</h4>
                                <p className="para-light">
                                    Although blockchain offers decentralization and speedy transactions, it lacks user-friendly features. Our WEB3 interfaces help newcomers easily navigate the blockchain landscape!
                                </p>
                            </div>                    
                        </div>
                    </div> 
                </div>
            </section> 

        </>
	);
}

function JoiningRewardsSection() {
	return (
        <>
            <section className="services d-flex align-items-center py-" id="joiningreward">
                <div className="container text-light">
                    <div className="text-center pb-4" >
                        <p>Reward Category 1</p> 
                        <h2 className="py-2">
                            <span className="reward_text">
                            Joining Rewards
                            </span>
                        </h2>
                        <p className="para-light">
                            Whenevere a new Metizen joins the community. The community gets rewarded.    
                        </p>
                    </div>
                    <div className="col-lg-12 d-flex align-items-center" data-aos="fade-right">
                            <img className="img-fluid" src="./assets/images/jreward.jpg" alt="work"/>        
                    </div>
                    <div className="row gy-4 py-2" data-aos="zoom-in">

                        {JOINING_REWARDS.map((reward) => (
                            // <Grid item xs={12} sm={6} md={3} key={stat.id}>
                                <RewardBox reward={reward} key={reward.id}/>
                            // </Grid>
                        ))}

                    </div> 
                </div>
            </section> 

        </>
	);
}

function TopupRewardsSection() {
	return (
        <>
            <section className="services d-flex align-items-center py-3" id="topupreward">
                <div className="container text-light">
                    <div className="text-center pb-4" >
                        <p>Reward Category 2</p> 
                        
                        <h2 className="py-2">
                            <span className="reward_text">
                            Top Up Rewards
                            </span>
                        </h2>
                        <p className="para-light">
                            Whenevere a new Metizen Tops Up to next level in the community. The community gets rewarded.    
                        </p>
                    </div>

                    <div className="col-lg-12 d-flex align-items-center" data-aos="fade-right">
                            <img className="img-fluid" src="./assets/images/jreward.jpg" alt="work"/>        
                    </div>

                    <div className="row gy-4 py-2" data-aos="zoom-in">

                        {TOPUP_REWARDS.map((reward) => (
                            // <Grid item xs={12} sm={6} md={3} key={stat.id}>
                                <RewardBox reward={reward} key={reward.id}/>
                            // </Grid>
                        ))}

                    </div> 
                </div>
            </section> 

        </>
	);
}

function MetaRewardsSection() {
	return (
        <>
            <section className="services d-flex align-items-center py-3" id="metareward">
                <div className="container text-light">
                    <div className="text-center pb-4" >
                        <p>Reward Category 3</p> 
                        
                        
                        <h2 className="py-2">
                            <span className="reward_text">
                            Meta Rewards
                            </span>
                        </h2>

                        <p className="para-light">
                            Earn Special Meta Rewards when you or your team reach the eligibility.    
                        </p>
                    </div>

                    <div className="col-lg-12 d-flex align-items-center" data-aos="fade-right">
                            <img className="img-fluid" src="./assets/images/mreward.jpg" alt="work"/>        
                    </div>

                    <div className="row gy-4 py-2" data-aos="zoom-in">

                        {META_REWARDS.map((reward) => (                            
                            <RewardBox reward={reward} key={reward.id}/>                            
                        ))}

                    </div> 
                </div>
            </section> 

        </>
	);
}

function RewardBox(props) {

    const reward=props.reward;
    const title=reward.title;
    const content=reward.content;
    const icon=reward.icon;
    const rewardvalue=reward.rewardvalue;
    const potentialearning=reward.potentialearning;

    return (
        <>
            <div className="col-lg-4">
                <div className="card bg-transparent">
                    <i className={icon}></i>

                    <h3 className="py-2" color={'secondary'}>{title}</h3>
                    <p className="para-light">
                        {content}
                    </p>
                    <h6 className="py-2">
                    <span className="reward_text">Reward Value: {rewardvalue}</span>
                    </h6>
                    {
                        potentialearning && 
                        <>
                            <h5 className="py-2">
                                <span className="reward_text">Earning Potential:</span>                    
                            </h5>
                            <h4 className="py-0">
                            <span className="reward_text"><b>{potentialearning} USDT</b></span>                    
                            </h4>
                        </>                        
                        
                    }


                </div>
            </div>
        </>
	);
}


function EarningsProjectionSection() {
	return (
        <>
                <section className="plans d-flex align-items-center py-5" id="plans">
                    <div className="container text-light" >
                        
                        <div className="text-center pb-4">
                            <p>EARNINGS PROJECTION</p>
                                                        
                            <h2 className="py-2">
                                <span className="reward_text">
                                    How much rewards can I earn?
                                </span>
                            </h2>


                            <p className="para-light">
                                Here we bring you a small calculation how much you can earn through the community.<br/>
                                Remember, You can start this journey with just 50 USDT.
                            </p>
                        </div>

                        <div className="row gy-4" data-aos="zoom-in">

                            <div className="col-lg-12 d-flex align-items-center" data-aos="fade-right">
                                <img className="img-fluid" src="./assets/images/earnings.jpg" alt="work"/>        
                            </div>
 
                        </div> 

                    </div> 
                </section> 

        </>
	);
}



export default CommunityPage;
