import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}°C`;
}



export default function DiscreteSlider(props) {

    const level=props?.level;
    const [value, setValue] = React.useState(10);

    const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    if(props?.onChange){
        props?.onChange(level,newValue)
    }
    };

  return (
    <Box sx={{ width: 250 }}>
      <Slider
        aria-label="Small steps"
        value={value}
        getAriaValueText={valuetext}
        step={1}
        marks
        min={0}
        max={10}
        valueLabelDisplay="auto"
        onChange={handleChange}
      />
    </Box>
  );
}
