import React, { useState,useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'

const compName='HomePage';
const HomePage = () => {
 
       


  return (
    <>
        <section className="home py-5 d-flex align-items-center" id="header">
        
        <video autoPlay muted loop>
            <source src="assets/video/HomeBGVideo.mp4" type="video/mp4"/>
            Your browser does not support the video tag.
        </video>

        <div className="overlay"></div>

        <div className="container content text-light py-5"  data-aos="fade-right"> 
            <h1 className="headline">Best <span className="home_text">WEB 3 PAYMENT</span><br/>Processing Services For You</h1>
            <p className="para para-light py-3">Welcome to Infinito Meta AI, a trailblazer in creating an advanced web3 ecosystem 
                where businesses can harness the power of cutting-edge Web3 platforms for seamless payment processing 
                solutions for companies and individual needs!</p>
            <div className="d-flex align-items-center">
                <p className="p-2"><i className="fas fa-atom fa-lg"></i></p>
                <p>Powered by Decentralization.</p>  
            </div>
            <div className="d-flex align-items-center">
                <p className="p-2"><i className="fas fa-wifi fa-lg"></i></p>
                <p>Driven by WEB3 Infrastructure.</p>  
            </div>
            <div >
                <a className="btn" href="#products">View Products</a>
            </div>
        </div> 
    </section> 
    {/* <!-- end of home --> */}


    {/* <!-- Information --> */}
        <InformationSection/>
    {/* <!-- end of information --> */}
    

    {/* <!-- About --> */}
        <AboutUsSection/>
    {/* <!-- end of about --> */}


    {/* <!-- Services --> */}
        <ProductsSection/>
    {/* <!-- end of services --> */}


    {/* <!-- Plans --> */}
        <PlansSection/>
    {/* <!-- end of plans --> */}


    {/* <!-- Work --> */}
        <OurWorkSection/>
    {/* <!-- end of work --> */}

    </>
  );
};

function InformationSection() {
	return (

        <>
            <section className="information">
                <div className="container-fluid">  
                    <div className="row text-light">
                        <div className="col-lg-4 text-center p-5" data-aos="zoom-in">
                            <i className="fas fa-tachometer-alt fa-3x p-2"></i>
                            <h4 className="py-3">Faster Services</h4>
                            <p className="para-light">Process your financial transactions in fraction of traditional times!</p>
                        </div>
                        <div className="col-lg-4 text-center p-5"  data-aos="zoom-in">
                            <i className="fas fa-clock fa-3x p-2"></i>
                            <h4 className="py-3">99% Service Uptime</h4>
                            <p className="para-light">Our robust infrastructure helps you to run your business round the clock!</p>
                        </div>
                        <div className="col-lg-4 text-center p-5 text-dark"  data-aos="zoom-in"> 
                            <i className="fas fa-headset fa-3x p-2"></i>
                            <h4 className="py-3">24/7 Support </h4>
                            <p className="para-light">Feel assured, we are always holding your back. Our highly trained support team will be able to assit you any time!</p>
                        </div>
                    </div>
                </div> 
            </section> 
        </>
	);
}


function AboutUsSection() {
	return (
        <>
                <section className="about d-flex align-items-center text-light py-5" id="about">
                    <div className="container" >
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-7" data-aos="fade-right">
                                <p>ABOUT US</p>
                                <h1>We are thriving WEB3 <br/> product company</h1>
                                <p className="py-2 para-light">Welcome to Infinito Meta AI, a trailblazer in creating an advanced web3 ecosystem 
                                    where businesses can harness the power of cutting-edge Web3 platforms for seamless payment processing
                                    solutions for companies and individual needs.</p>
                                <p className="py-2 para-light"></p>

                                <p>OUR VISION</p>
                                <p className="py-2 para-light">
                                    Our vision is to enable every business and individual to harness the benefit of Web3
                                    on their daily life and business operations. We continuously thrive to revolutionize 
                                    the way businesses operate by providing a robust, decentralized infrastructure that 
                                    enhances financial operations, improves efficiency, and drives growth. We aim to be 
                                    the leading provider of innovative Web3 solutions that empower businesses to 
                                    achieve their full potential in the digital age
                                </p>

                                <p>OUR MISSION</p>
                                <p className="py-2 para-light">
                                    At Infinito Meta AI, our mission is to simplify and streamline financial processes for businesses 
                                    of all sizes. We are committed to delivering state-of-the-art Web3 technologies that offer 
                                    unparalleled security, transparency, and efficiency. By integrating blockchain and other 
                                    decentralized technologies, we ensure that your financial operations are faster, more secure, 
                                    and more reliable than ever before.
                                </p>

                                <div className="my-3">
                                    <a className="btn" href="#your-link">Learn More</a>
                                </div>
                            </div>
                            <div className="col-lg-5 text-center py-4 py-sm-0" data-aos="fade-down"> 
                                <img className="img-fluid" src="./assets/images/about.jpg" alt="about" />
                            </div>
                        </div> 
                    </div> 
                </section> 
        </>
	);
}

function ProductsSection() {
	return (
        <>
            <section className="services d-flex align-items-center py-5" id="products">
                <div className="container text-light">
                    <div className="text-center pb-4" >
                        <p>OUR PRODUCTS</p> 
                        <h2 className="py-2">Explore unlimited possibilities</h2>
                        <p className="para-light">
                            Web3 and Decentralization have provided a platform where transparency and privacy can go hand in hand.<br/> Our Web3 products are 
                            built to bridge the gap between your needs and the solutions available through the blockchain and decentralization.    
                        </p>
                    </div>
                    <div className="row gy-4 py-2" data-aos="zoom-in">
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-donate fa-2x"></i>

                                <h4 className="py-2">PAYMENT COLLECTIONS</h4>
                                <p className="para-light">
                                    Enhance your payment collection process with our secure and efficient system. 
                                    Accept payments in from any where in the world, offering flexibility and convenience to your clients
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-file-alt fa-2x"></i>
                                <h4 className="py-2"> INVOICE PROCESSING</h4>
                                <p className="para-light">
                                    Automate your invoicing with our sophisticated Web3 platform, 
                                    reducing errors and speeding up processing times. 
                                    Enjoy the benefits of smart contracts to ensure accuracy and timely payments!
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-stream fa-2x"></i>                    
                                <h4 className="py-2">SUBSCRIPTION SERVICES</h4>
                                <p className="para-light">
                                    Leverage the subscription services to effectively establish your consistent and reliable payment collection process for the various services and products that you offer.
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-ribbon fa-2x"></i>                     
                                <h4 className="py-2">CHARITY COLLECTIONS</h4>
                                <p className="para-light">
                                    Are you struggling to receive donations for your charity globally? You can now set up your donation gateway and simplify the donation process for yourself and the donars.    
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-shipping-fast fa-2x"></i>
                                <h4 className="py-2">BULK PAYMENTS</h4>
                                <p className="para-light">
                                    Tired of sending payments individually. Use our bulk payment service and simplify your bulk payment process such as salary transfers, bulk vendor or invoice payments!
                                </p>
                            </div>                    
                        </div>
                        <div className="col-lg-4">
                            <div className="card bg-transparent">
                                <i className="fas fa-tv fa-2x"></i>
                                <h4 className="py-2">Crypto Services</h4>
                                <p className="para-light">
                                    Although blockchain offers decentralization and speedy transactions, it lacks user-friendly features. Our WEB3 interfaces help newcomers easily navigate the blockchain landscape!
                                </p>
                            </div>                    
                        </div>
                    </div> 
                </div>
            </section> 

        </>
	);
}

function PlansSection() {
	return (
        <>
                <section className="plans d-flex align-items-center py-5" id="plans">
                    <div className="container text-light" >
                        <div className="text-center pb-4">
                            <p>OUR PLANS</p>
                            <h2 className="py-2">Explore unlimited possibilities</h2>
                            <p className="para-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae asperiores, quia accusantium sunt corporis optio recusandae? Nostrum libero pariatur cumque, ipsa dolores voluptatibus voluptate alias sit fuga. Itaque, ea quo.</p>
                        </div>
                        <div className="row gy-4" data-aos="zoom-in">
                            <div className="col-lg-6">
                                <div className="card bg-transparent px-4">
                                    <h4 className="py-2">BASIC BUNDLE</h4>
                                    <p className="py-3">Perfect for Individuals.</p>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Payment Collections.</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Token Transfers.</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Charity Collections.</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-minus-square fa-1x"></i></p>
                                        <p>Invoice Processing</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-minus-square fa-1x"></i></p>
                                        <p>Subscription Service</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-minus-square fa-1x"></i></p>
                                        <p>Bulk Transfers</p>
                                    </div>

                                    <h4 className="py-3">$25/Year</h4>
                                    {/* <div className="my-3">
                                        <a className="btn" href="#your-link" >View Plans</a>
                                    </div>  */}
                                </div>  
                            </div>

                            <div className="col-lg-6">
                                <div className="card bg-transparent px-4">
                                    <h4 className="py-2">BUSINESS BUNDLE</h4>
                                    <p className="py-3">Perfect for Business.</p>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Payment Collections.</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Token Transfers.</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Charity Collections.</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Invoice Processing</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Subscription Service</p>
                                    </div>
                                    <div className="block d-flex align-items-center">
                                        <p className="pe-2"><i className="far fa-check-circle fa-1x"></i></p>
                                        <p>Bulk Transfers</p>
                                    </div>

                                    <h4 className="py-3">$49/Year</h4>
                                    {/* <div className="my-3">
                                        <a className="btn" href="#your-link" >View Plans</a>
                                    </div> */}
                                </div>  
                            </div>


                        </div> 
                    </div> 
                </section> 

        </>
	);
}

function OurWorkSection() {
	return (
        <>
            <section className="work d-flex align-items-center py-5" >
                <div className="container-fluid text-light">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right">
                            <img className="img-fluid" src="./assets/images/ourwork.jpg" alt="work"/>        
                        </div>
                        <div className="col-lg-5 d-flex align-items-center px-4 py-3" data-aos="">
                            <div className="row">
                                <div className="text-center text-lg-start py-4 pt-lg-0">
                                    <p>OUR WORK</p>
                                    <h2 className="py-2">We are building for tomorrow</h2>
                                    <p className="para-light">
                                        Break your silo, Join the community and work with like minded talents.
                                    </p>
                                </div>
                                <div className="container" data-aos="fade-up">
                                    <div className="row g-5">
                                        <div className="col-6 text-start" >
                                            <i className="fas fa-briefcase fa-2x text-start"></i>
                                            <h2 className="purecounter" data-purecounter-start="0" data-purecounter-end="10" data-purecounter-duration="1">10</h2>
                                            <p>PRODUCTS COMPLETED</p>
                                        </div>
                                        <div className="col-6" >
                                            <i className="fas fa-flag-usa fa-2x"></i>
                                            <h2 className="purecounter" data-purecounter-start="0" data-purecounter-end="12" data-purecounter-duration="3">12</h2>
                                            <p>COUNTRIES</p>
                                        </div>
                                        <div className="col-6">
                                            <i className="fas fa-users fa-2x"></i>
                                            <h2 className="purecounter" data-purecounter-start="0" data-purecounter-end="1255" data-purecounter-duration="3">10,000+</h2>
                                            <p>ACTIVE CUSTOMERS</p>
                                        </div>
                                        <div className="col-6">
                                            <i className="fas fa-clock fa-2x"></i>
                                            <h2 className="purecounter" data-purecounter-start="0" data-purecounter-end="1157" data-purecounter-duration="3">1,000</h2>
                                            <p>GOOD REVIEWS</p>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </section> 
        </>
	);
}


export default HomePage;
