import React, { useEffect, useState } from 'react'
import {useSelector,useDispatch} from 'react-redux'


import {appDataActions} from  '@store/slices/appdataslice.jsx';

import {SUCCESS_CODE } from '@appcomponents/utils/errorcodes.jsx'

import GeneralAPI from '@appcomponents/api/generalapi.jsx'

import { setApplicationEnvironment } from '@appcomponents/utils/appconfig.jsx';

const compName='BaseLoader';
const BaseLoader = () => {
  
  const dispatch = useDispatch();  
  
  const {getPublicData} = GeneralAPI();


  
  const fetchData = async () => {
 
    fetchPublicData();

  }

  const fetchPublicData = async () => {
  
    const requestBody = {        
      user_ip:'NA' 
    };

    const responsedata = await getPublicData( requestBody);
    if( responsedata.responseCode === SUCCESS_CODE){

      const socialMediaList=responsedata.respObject.social_media_list;
      const generalSettingsList=responsedata.respObject.general_settings_list;
      const appSettingsList=responsedata.respObject.app_settings_list;
      const chainSettingsList=responsedata.respObject.chain_settings_list;
      

      const chainDetails = chainSettingsList.reduce((acc, item) => {
                                                                  acc[item.key] = item.value;
                                                                  return acc;
                                                                }, {});
      

      dispatch(appDataActions.updateSMList(socialMediaList));
      dispatch(appDataActions.updateGSList(generalSettingsList));
      dispatch(appDataActions.updateAppList(appSettingsList));
      dispatch(appDataActions.updateChainList(chainDetails));

      console.log(compName+" Success on fetch Public APP data details. ");
      

      setApplicationEnvironment(appSettingsList);

    }else{
      console.log(compName+" Public data detail fetch failed. " + responsedata.responseMessage);
    }

  }
  
  useEffect(() => {
      console.log(compName+": Use effect[] initialising");
      fetchData();   
  }, []);

  

  return (
    <>


    </>
  );
}

export default BaseLoader;
