import React, { useEffect, useState } from 'react'

import {useSelector,useDispatch} from 'react-redux'
import { menuActions } from '../../features/slices/menuslice.jsx'
import HomeNavbar from './homenavbar.jsx';
import Footer from '../../pages/basicpages/Footer.jsx';
import Body from './body.jsx';
import ContactUs from '../../pages/basicpages/contactus.jsx';
import ResponsiveAppBar from './ResponsiveAppBar.jsx';


const compName='Home';

const Home = (props) => {

  const dispatch = useDispatch();  
  
  const menuEvent={menuValue:props.MenuLandingPage,activeMenu:props.MenuLandingPage};
  dispatch(menuActions.updateHomeMenu(menuEvent));


  useEffect(() => {
      console.log(compName+": Use effect[] initialising");
      //fetchData();   
  }, []);
  
 

  return (
    <>    
    {/* <ResponsiveAppBar/> */}
    {/* <HomeNavbar />      */}
    <Body/>

    </>

  )
}

export default Home;
