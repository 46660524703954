import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Card, CardHeader, Divider, Grid, MenuList, Stack, Typography } from '@mui/material';

import calcHeaderHeight from '@helpers/layoutHeight';
import DiscreteSliderMarks from '../../elements/DiscreetSlider';

const MembershipRewards = () => {
  const joiningFee = 50; // 50 UST
  const topupFee = 50; // 50 USDT
  const levels = Array.from({ length: 10 }, (_, i) => i + 1); // Levels 1 to 10

  const initialMembersAtLevels = levels.map(level => ({ members: Math.pow(3, level - 1) * 3, topupLevel: 10 }));

  const [selectedTopupLevel, setSelectedTopupLevel] = useState(10);
  const [directReferrals, setDirectReferrals] = useState(0);
  const [membersAtLevels, setMembersAtLevels] = useState(initialMembersAtLevels);



  const handleLevelChange = (levelIndex,newValue) => {
    
    console.log(levelIndex,newValue);
    const newMembersAtLevels = [...membersAtLevels];
    newMembersAtLevels[levelIndex] = {
      ...newMembersAtLevels[levelIndex],
      topupLevel: parseInt(newValue, 10),
    };
    setMembersAtLevels(newMembersAtLevels);
    
  };


  const handleDirectReferralsChange = (event) => {
    setDirectReferrals(event.target.value);
  };

  const handleMembersAtLevelsChange = (levelIndex, event) => {
    const newMembersAtLevels = [...membersAtLevels];
    newMembersAtLevels[levelIndex] = {
      ...newMembersAtLevels[levelIndex],
      members: parseInt(event.target.value.replace(/,/g, ''), 10),
    };
    setMembersAtLevels(newMembersAtLevels);
  };

  const calculateRewards = () => {

    let totalRewards = 0;
    let totalTopupRewards = 0;
    let totalMembers = 0;
    
    let joiningSponsorRewards =  20 * directReferrals;
    let joiningLevelRewards =  0;

    let topupBonusRewardCount =  0;
    membersAtLevels.forEach(({ members, topupLevel },index) => {
      const topupLevelRewards = members * topupLevel * 1;
      totalTopupRewards += topupLevelRewards;
      totalMembers += members;
      if(index+1 <= topupLevel){
        topupBonusRewardCount = topupBonusRewardCount+members;
      }
    });

    joiningLevelRewards =  totalMembers * 1;

    let topupSponsorRewards =  0;
    let topupLevelRewards = 1 * totalTopupRewards;
    let topupBonusRewardValue = 20 * topupBonusRewardCount;

    if (totalTopupRewards > directReferrals) {    
      topupSponsorRewards=10 * directReferrals;      
    } else {    
      topupSponsorRewards=10 * totalTopupRewards;
    }

    totalRewards=topupBonusRewardValue+topupSponsorRewards+topupLevelRewards+joiningSponsorRewards+joiningLevelRewards;

    return {
      topupBonusRewardValue,
      topupSponsorRewards,
      topupLevelRewards,
      joiningSponsorRewards,
      joiningLevelRewards,
      totalRewards,
    };
  };

  const formatNumber = (number) => {
    if (number == null || isNaN(number)) return '';
    return number.toLocaleString();
  };

  const totalMembers = membersAtLevels.reduce((total, { members }) => total + members, 0);

  const { topupBonusRewardValue,topupSponsorRewards, topupLevelRewards,
          joiningSponsorRewards,joiningLevelRewards,totalRewards } = calculateRewards();




  var main=0;
  return (
    <>

    
      <Stack component={Card}	height="100%" p={3} direction="column" spacing={2} //bgcolor={bgColor}
                    sx={{
                        overflow: 'visible',
                        transition: 'all 0.5s',
                        position: 'relative',
                        border: 0,
                        borderColor:'border',
                        '&:hover': {
                            transform: 'translateY(-10px)',
                        },
                    }}
                >
                    
                    <h2>Membership Fees and Rewards</h2>
                    
      </Stack>

      <br/>




      <Grid container rowSpacing={1

      } columnSpacing={4}>

        <Grid item xs={12} sm={8} md={8}>
          <Card type="section"> 
          <Stack  direction="column" spacing={2} >


              <Stack component={Card}	height="100%" p={3} direction="column" spacing={2} bgcolor={'success.light'}
                  sx={{
                      overflow: 'visible',
                      transition: 'all 0.5s',
                      position: 'relative',
                      border: main ? 2 : 0,
                      borderColor: main ? 'success.main' : 'border',
                      '&:hover': {
                          transform: 'translateY(-10px)',
                      },
                  }}
              >


                  <Stack  pr={3} direction="row" spacing={2}>
                  
                      <label>{`Joining Fee: ${formatNumber(joiningFee)} USDT`}</label>     

                  </Stack>   

                  <Stack  pr={3} direction="row" spacing={2}>

                      <label>Direct Referrals:</label>
                      <TextField type="number" value={directReferrals}
                          onChange={handleDirectReferralsChange}
                          sx={{ width: '25ch', margin: '0.5rem' ,height: '5ch' }}/>

                  </Stack>


              </Stack>   


              <Stack component={Card}	height="100%" p={3} direction="column" spacing={2} bgcolor={'success.light'}
                  sx={{
                      overflow: 'visible',
                      transition: 'all 0.5s',
                      position: 'relative',
                      border: main ? 2 : 0,
                      borderColor: main ? 'primary.main' : 'border',
                      '&:hover': {
                          transform: 'translateY(-10px)',
                      },
                  }}
              >
                  <label>{`Top-up Fee: ${formatNumber(topupFee)} USDT`}</label>              

                      {membersAtLevels.map(({ members, topupLevel }, index) => (
                          <div key={index}>

                            <Stack  pr={1} direction="row" spacing={2}>
     

                              <label>Level {index + 1} Top-Up </label>
                              
                              <DiscreteSliderMarks level={index} onChange={handleLevelChange}/>
                              <label>{`Members: ${formatNumber(members)}`}</label>
                              {/* <span>{formatNumber(members)}</span> */}


     
                            </Stack>
                          </div>
                      ))}
                      <div>
                          <label>{`Total Members across all Levels: ${formatNumber(totalMembers)} Metizens`}</label> 

                      </div>

              </Stack> 

              </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
            <Card
              sx={{
                position: 'sticky',
                top: `${calcHeaderHeight('nav', false) + 25}px`,
              }}
              component="aside"
            >
            
            <Stack p={1} direction="column" spacing={2} 
                    sx={{
                              overflow: 'visible',
                              transition: 'all 0.5s',
                              position: 'relative',
                              border: main ? 2 : 0,
                              borderColor: main ? 'primary.main' : 'border',
                              '&:hover': {
                                  transform: 'translateY(-10px)',
                              },
                          }}
                          bgcolor={'success.light'}
          >
                              
              <h3>Rewards Calculation</h3>
            

{/*               
                  <Button          
                      variant="contained"
                      color="primary"
                      onClick={calculateRewards}
                  >
                  Calculate Rewards
                  </Button> */}
              

              
                {/* <label>{`Joining Sponsor Rewards: ${formatNumber(joiningSponsorRewards)} USDT`}</label> 
                <label>{`Joining Level Rewards: ${formatNumber(joiningLevelRewards)} USDT`}</label> 
              
              
                <label>{`Total Top-up Bonus Rewards: ${formatNumber(topupBonusRewardValue)} USDT`}</label> 
                <label>{`Total Top-up Sponsor Rewards: ${formatNumber(topupSponsorRewards)} USDT`}</label> 
                <label>{`Total Top-up Level Rewards: ${formatNumber(topupLevelRewards)} USDT`}</label> 

              

              <label>{`Total Earnings Rewards: ${formatNumber(totalRewards)} USDT`}</label>  */}


              <Grid
                container
                sx={{
                  borderRadius: 1,
                  overflow: 'hidden',
                  bgcolor: 'background.paper',
                  boxShadow: 26,
                  '--Grid-borderWidth': '1px',
                  borderTop: 'var(--Grid-borderWidth) solid',
                  borderLeft: 'var(--Grid-borderWidth) solid',
                  borderColor: 'border',
                  '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'border',
                  },
                }}
              >
                  <Grid item xs={12} sm={6} md={6}>
                    <EarningCard subtitle="Joining Category" title="Sponsor Reward" value={formatNumber(joiningSponsorRewards)} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <EarningCard subtitle="Joining Category" title="Level Reward" value={formatNumber(joiningLevelRewards)} />
                  </Grid>

              </Grid>

              <Grid
                container
                sx={{
                  borderRadius: 1,
                  overflow: 'hidden',
                  bgcolor: 'background.paper',
                  boxShadow: 26,
                  '--Grid-borderWidth': '1px',
                  borderTop: 'var(--Grid-borderWidth) solid',
                  borderLeft: 'var(--Grid-borderWidth) solid',
                  borderColor: 'border',
                  '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'border',
                  },
                }}
              >
                  <Grid item xs={12} sm={6} md={6}>
                    <EarningCard subtitle="Topup Category" title="Sponsor Reward"  value={formatNumber(topupSponsorRewards)} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <EarningCard subtitle="Topup Category" title="Level Reward"value={formatNumber(topupLevelRewards)} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <EarningCard subtitle="Topup Category" title="Bonus Reward"value={formatNumber(topupBonusRewardValue)} />
                  </Grid>

              </Grid>

              <Grid
                container
                sx={{
                  borderRadius: 1,
                  overflow: 'hidden',
                  bgcolor: 'warning.light',
                  boxShadow: 26,
                  '--Grid-borderWidth': '1px',
                  borderTop: 'var(--Grid-borderWidth) solid',
                  borderLeft: 'var(--Grid-borderWidth) solid',
                  borderColor: 'border',
                  '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'border',
                  },
                }}
              >

                  <Grid item xs={12} sm={12} md={12}>
                    <EarningCard subtitle="All Categories" title="Total Earnings"value={formatNumber(totalRewards)} />
                  </Grid>

              </Grid>


            </Stack>

          </Card>

        </Grid>

      </Grid>
    



    </>
  );
};


function EarningCard({ title,subtitle, value }) {
	return (
		<Stack spacing={0} p={1}>
			<Typography variant="subtitle2" textTransform="uppercase" color="text.tertiary">
				{title}
			</Typography>
			<Typography fontSize={25}>${value}</Typography>
			<Typography variant="caption">{subtitle}</Typography>
		</Stack>
	);
}

export default MembershipRewards;
