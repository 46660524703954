

function updateWalletDefList (state, action)  {    
  state.walletDefListSD = action.payload;    
}

function updateSMList (state, action)  {    
  state.socialMediaListSD = action.payload;    
}

function updateGSList (state, action)  {    
  state.generalSettingListSD = action.payload;    
}

function updateAppList (state, action)  {    
  state.appSettingListSD = action.payload;    
}

export {
  updateWalletDefList,
  updateSMList,updateGSList,updateAppList
}