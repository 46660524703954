import React , { useEffect} from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home.jsx';

import Layout from './layout.jsx';

import {useSelector,useDispatch} from 'react-redux'
  
import MissingPage from '@appcomponents/pages/errorpages/missingpage.jsx';
import MainLayout from '@appcomponents/structure/layouts/mainLayout/mainlayout.jsx';

  const compName='MasterContainer';
  function MasterContainer() {

  const dispatch = useDispatch();  


  const fetchData = async () => {
    
  
  }




  return (
    <>
      {console.log(compName+': Rendering : ')}
      <div className="App">

        <Router>
          <Routes>
            {/* <Route path="/" element={<Layout />}> */}
            <Route path="/" element={<MainLayout />}>

                {/* Public routes */}
                <Route path="/" element={<Home MenuLandingPage='HM'/>} />
                <Route path="/index.html" element={<Home  />} />            
                {/* <Route path={URI_LOGIN} element={<Home MenuLandingPage='LG' />} />
                <Route path={URI_SIGNUP} element={<Home MenuLandingPage='RC' />} />
                <Route path={URI_LOGOUT} element={<Home MenuLandingPage='LO' />} />
                <Route path={URI_CONTACT} element={<Home MenuLandingPage='CT' />} />                
                <Route path={URI_UNAUTHORIZED} element={<Home MenuLandingPage='UA' />} /> */}



            </Route>

              {/* catch all */}
            <Route path="*" element={<MissingPage/>} />

          </Routes>
        </Router>
     
      </div>

    </>

  );
}

export default MasterContainer;
