

const MissingPage = () => {
    return (
        <article style={{ padding: "100px" }}>
            
            <div className="section-title mb-0">
                <h4 className="m-0 text-uppercase font-weight-bold">Oops! Page not found</h4>
            </div>
            <hr/>
            <div className="flexGrow">
            <a className="h6 m-0 text-secondary font-weight-bold" href="/">Goto Homepage...</a>
                
            </div>
        </article>
    )
}

export default MissingPage