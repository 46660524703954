import axios from 'axios';
import { HOST_URL } from '@appcomponents/utils/appconfig';

const BASE_URL = HOST_URL;

const APP_URL = '/tc/v1/';

const REST_URL = BASE_URL+APP_URL;


export default axios.create({
    baseURL: REST_URL
});

export const axiosPrivate = axios.create({
    baseURL: REST_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});