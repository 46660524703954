import React, { useState ,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import CryptoJS from 'crypto-js';



const compName='AppUtil';
const AppUtil = () => {

  const appSettingList=useSelector(state => state.appdataReducer.appSettingListSD);

  const GOOGLE_CAPTCHA_KEY='GC_KEY';
  const ENCODER_KEY='ENCODER_KEY';
  const CAPTCHA_ENABLED='CPTC_ENABLED';
    
  const [appSettingData, setAppSettingData] = useState({ });
  const saveFormData = ( name, value) => {
      console.log(`${compName}: Saving AppConfig -- Name:'${name}' Value:'${value}' `);
      setAppSettingData((prevSelItem) => ({
      ...prevSelItem,[name]: value,
      })); 
  };

  const fetchData = async () => {

    if(Array.isArray(appSettingList)){       
        appSettingList.map((listItem,index) => {
            saveFormData(listItem.key,listItem.value);
            }
        )
    }else{
        console.error(compName+": Unable to get the Public App data from cache");
    }
  }

  useEffect(() => {      
      console.log(compName+": Use effect[appSettingList] initialising");
      fetchData(); 
  }, [appSettingList]);

  const getDashboardURL = () => {
      
    const refURL =`${appSettingData?.WEB_HTTP}://${appSettingData?.WEB_HOST}`;  
    return refURL;
  
  };

  // const generateNodeReferral = (nodeId) => {
  
  //   const encryptedId=  encrypt(nodeId.toString());
  //   const refURL =`${appSettingData?.WEB_HTTP}://${appSettingData?.WEB_HOST}${URI_JOIN}/${encryptedId}`;  
  //   return refURL;
  
  // };



  const getAppData = (key) => {     
    const envValue =appSettingData?.[key] ;
    return envValue;  
  };
  
  const getGoogleCaptchaKey = () => {    
    var enableValue= getAppData(CAPTCHA_ENABLED);
    var enableCaptcha= (enableValue === 'YES' || enableValue === 'yes'
            || enableValue === 'Y' ||enableValue === 'y'
    )    
    return (enableCaptcha)?getAppData(GOOGLE_CAPTCHA_KEY):undefined;  
  };

  const isCaptchaEnabled = () => {       
    var enableValue= getAppData(CAPTCHA_ENABLED);
    return (enableValue === 'YES' || enableValue === 'yes'
            || enableValue === 'Y' ||enableValue === 'y'
    );  
  };

  const getEncoderKey = () => {        
    return getAppData(ENCODER_KEY);  
  };
  


  const encrypt = (value) => {
    const encoderKey=getEncoderKey();
    if(encoderKey){
      const encrypted = CryptoJS.AES.encrypt(value.toString(), encoderKey).toString();
      return base64urlEncode(encrypted);
    }

  };
  
  const decrypt = (encryptedValue) => {


    const encoderKey=getEncoderKey();
    if(encoderKey){
      const encrypted = base64urlDecode(encryptedValue);
      const bytes = CryptoJS.AES.decrypt(encrypted, encoderKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  };


  return {
    fetchData,getAppData,
    getGoogleCaptchaKey,getEncoderKey,encrypt,decrypt,
    isCaptchaEnabled,getDashboardURL
  };

}
export default AppUtil;


// URL-safe base64 encoding
const base64urlEncode = (str) => {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

// URL-safe base64 decoding
const base64urlDecode = (str) => {
  str = str.replace(/-/g, '+').replace(/_/g, '/');
  while (str.length % 4) {
    str += '=';
  }
  return str;
};

