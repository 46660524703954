import React from 'react'
import { Twitter,Facebook,Youtube, Instagram,Linkedin} from 'react-bootstrap-icons'
import {useSelector} from 'react-redux'

const compName='SocialHeader';
const SocialHeader = (props) => {

    const size=(props?.size)?props?.size:'big';
    var displayStyle="d-flex justify-content-center align-items-center mr-3" ;
    if(size === 'big'){
      displayStyle="btn btn-lg btn-secondary btn-lg-square mr-2";
    }
    
    const socialMediaList=useSelector(state => state.appdataReducer.socialMediaListSD);
    

    var matchedItems;
    if(socialMediaList?.length>0){
        const smArray=['Twitter','Linkedin','Instagram','Facebook','Youtube']
        matchedItems = smArray.map(sm => {
            const matchingItem = socialMediaList.find(item => item.media_name === sm);
            return matchingItem ? { [sm]: matchingItem.link } : null;
        }).filter(Boolean);
        console.log(compName+" Matched SM items: ",matchedItems);
    }

    const generateIcon = (iconName) => {
        switch (iconName) {
          case 'Twitter':
            return <Twitter />;
          case 'Facebook':
            return <Facebook />;
          case 'Youtube':
            return <Youtube />;
          case 'Instagram':
            return <Instagram />;
          case 'Linkedin':
            return <Linkedin />;
          default:
            return null;
        }
    };


    return (
        
        <>
            {console.log(compName+': Rendering : ',matchedItems)}
            {
            (matchedItems && 
              <div className="d-flex align-items-center flex-wrap">

              {matchedItems.map((matchedItem, index) => {
                  const [iconName, linkValue] = Object.entries(matchedItem)[0]; // Get the icon name and href

                  return (
                  <div key={index}>
                      <a className={displayStyle}
                      href={linkValue} target="_blank" rel="noopener noreferrer">
                      <small>{generateIcon(iconName)}</small> 
                      </a>
                      
                  </div>

                  );
              })}

            </div>
            )
            }  
        </>
    )
}

export default SocialHeader