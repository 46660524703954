import React, { useState ,useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import {useSelector,useDispatch} from 'react-redux'
import { menuActions } from '../../features/slices/menuslice.jsx'
import { Button } from '@mui/material';
import AppUtil from '@appcomponents/utils/apputils.jsx';


const compName='HomeNavbar';
const HomeNavbar = () => {

    const { getDashboardURL} = AppUtil();  

    const dispatch = useDispatch();
    
    const menuValueObj = useSelector(state => state.menuReducer.homeMenuSD)   
    const activeTab = (menuValueObj?.activeMenu)?menuValueObj.activeMenu:'HM';
    
       
    const handleItemClick  = (newMenuValue , newActiveMenu) => {
        const menuEvent={menuValue:newMenuValue,activeMenu:newActiveMenu};
        dispatch(menuActions.updateHomeMenu(menuEvent));
    };

    const openNewTab = (path) => {
        // Open the route in a new tab
        window.open(path, '_blank');
    };

    const openDashboard = () => {
        // Open the route in a new tab
        window.open(getDashboardURL(), '_blank');
    };


    return (
    <>
    
    <nav id="navbar" className="navbar navbar-expand-lg fixed-top navbar-dark" aria-label="Main navigation">
        <div className="container">

            {/* Image Logo */}
            <a className="navbar-brand logo-image" href="/">
                <img src="./assets/images/color_logotext.png" alt="alternative"/>
            </a> 

            {/* Text Logo - Use this if you don't have a graphic logo */}
            {/* <a className="navbar-brand logo-text" href="/">Infinito Meta AI</a> */}

            <button className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault" >
                <ul className="navbar-nav ms-auto navbar-nav-scroll">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" onClick={() => handleItemClick('HM','HM')}>Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => handleItemClick('ABT','ABT')}>About</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link"  onClick={() => handleItemClick('PDS','PDS')}>Products</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => handleItemClick('CPH','CP')}>Community Program</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => handleItemClick('CCL','CP')}>Rewards Calculator</a>
                    </li>

                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Community</a>
                        
                        <ul className="dropdown-menu" aria-labelledby="dropdown01">
                            <li>
                                <a className="dropdown-item" onClick={() => handleItemClick('CPH','CP')}>Community Program</a>
                            </li>
                            <li><div className="dropdown-divider"></div></li>
                            <li>
                                <a className="dropdown-item" onClick={() => handleItemClick('CCL','CP')}>Rewards Calculator</a>
                            </li>
                          
                        </ul>
                    </li> */}
                    {/* <li className="nav-item">
                        <a className="nav-link" href="#contact">Contact</a>
                    </li> */}

                    <li className="nav-item">
                        <Button variant="contained" color="success" 
                            onClick={() => openDashboard()}>
                                Login
                        </Button>
                    </li>

                </ul>
                {/* <span className="nav-item social-icons">
                    <span className="fa-stack">
                        <a href="#your-link">
                            <i className="fas fa-circle fa-stack-2x"></i>
                            <i className="fab fa-facebook-f fa-stack-1x"></i>
                        </a>
                    </span>
                    <span className="fa-stack">
                        <a href="#your-link">
                            <i className="fas fa-circle fa-stack-2x"></i>
                            <i className="fab fa-twitter fa-stack-1x"></i>
                        </a>
                    </span>
                </span> */}
            </div> 
        </div> 
    </nav> 
    

    </>
    )
}

export default HomeNavbar